<div class="generate-reports-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Generate New Year</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="field-full-width">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Client Name" #input>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table mat-table class="datatable" [dataSource]="dataSource">
          <!--Municipality-->
          <ng-container matColumnDef="Municipality">
            <th mat-header-cell *matHeaderCellDef>Client</th>
            <td mat-cell *matCellDef="let element">{{element.fullName}}</td>
          </ng-container>
          <!--Years-->
          <ng-container matColumnDef="Years">
            <th mat-header-cell *matHeaderCellDef>Years</th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-list aria-label="Years">
                <mat-chip *ngFor="let year of element.municipalityYear">{{year}}</mat-chip>
              </mat-chip-list>
              <!--<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" [disabled]="element.municipalityYear.length == 0">
                <mat-icon>expand_more</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let year of element.municipalityYear">
                  <span>{{year}}</span>
                </button>
              </mat-menu>-->
            </td>
          </ng-container>
          <!--Action-->
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="accent" aria-label="Add new year" (click)="openGenerateNewYearDialog(element.id)">
                <mat-icon>add_circle_outline</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="generateData"></tr>
          <tr mat-row *matRowDef="let row; columns: generateData;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
