<div class="home-section-wrapper">
  <div class="row">
    <!--Freeze-updates-->
    <div class="col-md-12">
      <section class="mat-elevation-z8 white-box">
        <h1 class="title-box">Freeze Updates</h1>
        <!-- Filters -->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="fill" class="field-full-width">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Client Name" #input>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="field-full-width px-2" appearance="fill">
              <mat-label>Select year</mat-label>
              <mat-select [(ngModel)]="freezeUpdatesYear" (selectionChange)="changeFreezeYear()">
                <mat-option *ngFor="let year of years" [value]="year.municipalityYear">
                  {{ year.municipalityYear }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- Results Table -->
        <table mat-table class="datatable" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="Municipality">
            <th mat-header-cell *matHeaderCellDef> Client </th>
            <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
          </ng-container>
          <ng-container matColumnDef="Year">
            <th mat-header-cell *matHeaderCellDef> Year </th>
            <td mat-cell *matCellDef="let element"> {{element.municipalityYear}} </td>
          </ng-container>
          <ng-container matColumnDef="Locked">
            <th mat-header-cell *matHeaderCellDef> Locked </th>
            <td mat-cell *matCellDef="let element"><mat-slide-toggle ngDefaultControl [(ngModel)]="element.locked" (change)="freezeMunicipality(element)"></mat-slide-toggle></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="freezeUpdates"></tr>
          <tr mat-row *matRowDef="let row; columns: freezeUpdates;"></tr>
        </table>
      </section>
    </div>
  </div>
</div>
