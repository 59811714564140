<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Generate new year</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="generateNewYearForm" (ngSubmit)="submitGenerateNewYearForm(generateNewYearForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <mat-label>Year<span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number"
                     matInput
                     formControlName="MunicipalityYear"
                     maxlength="4"
                     (input)="restrictYearLength($event)" />
              <mat-error *ngIf="generateNewYearForm.controls['MunicipalityYear'].hasError('required')">
                This field is required
              </mat-error>
              <mat-error *ngIf="generateNewYearForm.controls['MunicipalityYear'].hasError('pattern')">
                Please enter a valid 4-digit year
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="dialog-action-buttons">
              <div mat-dialog-actions class="float-right">
                <button mat-button type="button" (click)="onNoClick()">Cancel</button>
                <button mat-button type="submit" mat-raised-button color="success" [disabled]="!generateNewYearForm.valid">Create</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
