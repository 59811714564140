<div class="general-info-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">

    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Schedule Reports</h3>
        </div>
      </div>
    </div>

    <div class="row">
      <mat-list class="reports-list">

        <a href="/api/Reports/GetEquipmentSchedule?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Equipment Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetFineArtSchedule?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Fine Art Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetLocationsAndProperty?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Locations & Property Values Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetPayrollSchedule?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Payroll Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetVehicleSchedule?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Vehicle Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetWatercraftSchedule?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Watercraft Schedule</div>
          </mat-list-item>
        </a>

      </mat-list>
    </div>

  </section>
</div>


<div class="general-info-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">

    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Other Reports</h3>
        </div>
      </div>
    </div>

    <div class="row">
      <mat-list class="reports-list">
        <a href="/api/Reports/GetRenewalApplication?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Renewal Application</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetCommercialCrime?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Commercial Crime</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetCyberLiability?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Cyber Liability</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetEmployeeConcentration?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Employee Concentration</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetNonOwnedAircraftLiability?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>NonOwned Aircraft Liability</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetPositionBondApplication?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Position Bond Application</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetPositionBondWorksheet?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Position Bond Worksheet</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetSiteEnvironmentalLiability?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Site Environmental Liability</div>
          </mat-list-item>
        </a>

        <a href="/api/Reports/GetUSTLiability?municipalityID={{activeMunicipality.municipalityID}}&municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>UST Liability</div>
          </mat-list-item>
        </a>

        <a href="/reports/2022-GSMJIF-Certificate-Request-Form.pdf" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>GSMJIF Certificate Request Form</div>
          </mat-list-item>
        </a>
      </mat-list>
    </div>

  </section>
</div>
